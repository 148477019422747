import { PermissionCheckMixin, EXO_DATA, EXO_STATUS, EXO_SUMMARY_REPORT, EXO_SUMMARY_BY_TRACKING_STATUS, EXO_NOTE_REASON, EXO_TRACKING_STATUS, EXO_MANAGE_OWNER, VIEW_PERMISSION } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "body h-100"
  }, [_c("portal", {
    attrs: {
      "to": "sider-items"
    }
  }, [_c("div", {
    staticClass: "kh-application p-4"
  }, [_c("p", {
    staticClass: "m-0"
  }, [_vm._v("EXPORT ORDERS")]), _c("h5", {
    staticClass: "mb-0"
  }, [_vm._v("Export Orders")])]), _c("a-menu", {
    attrs: {
      "open-keys": _vm.openKeys,
      "mode": "inline",
      "theme": "light",
      "inline-collapsed": _vm.collapsed,
      "selected-keys": _vm.selectedKeys
    }
  }, [_vm._l(_vm.dataMenu, function(item) {
    return [item.child.length === 0 && (item.key === "home" || _vm.$can(_vm.permissions.view, item.page)) ? [_c("a-menu-item", {
      key: item.key,
      on: {
        "click": function($event) {
          return _vm.clickItem($event);
        }
      }
    }, [_c("router-link", {
      attrs: {
        "to": item.path
      }
    }, [_c("a-icon", {
      attrs: {
        "type": item.icon
      }
    }), _c("span", [_vm._v(_vm._s(item.title))])], 1)], 1)] : _vm._e(), item.child.length > 0 ? [_c("a-sub-menu", {
      key: item.key,
      on: {
        "titleClick": function($event) {
          return _vm.clickParentItem($event);
        }
      }
    }, [_c("span", {
      attrs: {
        "slot": "title"
      },
      slot: "title"
    }, [_c("a-icon", {
      attrs: {
        "type": item.icon
      }
    }), _c("span", [_vm._v(_vm._s(item.title))])], 1), _vm._l(item.child, function(itemChild) {
      return [_vm.$can(_vm.permissions.view, itemChild.page) ? _c("a-menu-item", {
        key: itemChild.key,
        on: {
          "click": function($event) {
            return _vm.clickItem($event);
          }
        }
      }, [_c("router-link", {
        attrs: {
          "to": itemChild.path
        }
      }, [_vm._v(_vm._s(itemChild.title))])], 1) : _vm._e()];
    })], 2)] : _vm._e()];
  })], 2)], 1), _c("router-view", {
    class: _vm.isPermissionsLoading ? "hide" : ""
  })], 1);
};
var staticRenderFns = [];
var ExportOrders_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script = {
  name: "ExportOrders",
  mixins: [PermissionCheckMixin],
  data() {
    return {
      collapsed: false,
      dataMenu: [
        {
          title: "Back to Main Menu",
          path: "/",
          key: "home",
          icon: "appstore",
          child: []
        },
        {
          title: "Export Orders Data",
          path: "/export-orders/export-orders-data",
          key: "export-orders-data",
          icon: "container",
          page: EXO_DATA,
          child: []
        },
        {
          title: "Export Orders Status",
          path: "/export-orders/export-orders-status",
          key: "export-orders-status",
          icon: "form",
          page: EXO_STATUS,
          child: []
        },
        {
          title: "Summary Report",
          path: "/export-orders/summary-report",
          key: "summary-report",
          icon: "file-text",
          page: EXO_SUMMARY_REPORT,
          child: []
        },
        {
          title: "Summary By Tracking Status",
          path: "/export-orders/summary-tracking-status",
          key: "summary-tracking-status",
          icon: "control",
          page: EXO_SUMMARY_BY_TRACKING_STATUS,
          child: []
        },
        {
          title: "Master Files",
          path: "",
          key: "masterfiles",
          icon: "tool",
          child: [
            {
              title: "Note Reasons",
              path: "/export-orders/note-reasons",
              key: "note-reasons",
              page: EXO_NOTE_REASON
            },
            {
              title: "Tracking Status",
              path: "/export-orders/tracking-status",
              key: "tracking-status",
              page: EXO_TRACKING_STATUS
            },
            {
              title: "Owner",
              path: "/export-orders/owner",
              key: "owner",
              page: EXO_MANAGE_OWNER
            }
          ]
        }
      ],
      selectedKeys: [],
      openKeys: [],
      rootSubmenuKeys: [],
      permissions: {
        view: VIEW_PERMISSION
      }
    };
  },
  metaInfo: {
    title: "Export Orders"
  },
  computed: {
    isPermissionsLoading() {
      return false;
    }
  },
  created() {
    this.updateMenu();
  },
  methods: {
    updateMenu: function() {
      let path = this.$route.matched[1].path;
      this.openKeys = typeof this.$route.matched[1].meta == "string" ? [this.$route.matched[1].meta.split(";")[0]] : [];
      let title = path.substring(path.lastIndexOf("/") + 1);
      this.selectedKeys = [title];
    },
    clickItem: function(e) {
      this.selectedKeys = [e.key];
    },
    clickParentItem(e) {
      if (!this.openKeys.includes(e.key)) {
        this.openKeys = [e.key];
      } else {
        const keyIndex = this.openKeys.indexOf(e.key);
        if (keyIndex > -1)
          this.openKeys.splice(keyIndex, 1);
      }
    }
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, "41b9074a", null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var ExportOrders = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { ExportOrders as default };
